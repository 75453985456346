import {
    TypeAccessLogin,
    TypeAccessRegistration,
    TypeAuthUser,
    TypeDefaultLoginDetails,
    TypeRedirectAfterLogin,
    TypeTeamMember,
    userRoleId,
} from "./types"

export type State = {
    accessRegistration: TypeAccessRegistration
    authUser: TypeAuthUser
    accessLogin: TypeAccessLogin
    defaultLoginDetails: TypeDefaultLoginDetails
    isUserRegistered: boolean
    redirectAfterLogin: TypeRedirectAfterLogin
    teamMembers: TypeTeamMember
    userRoleId: userRoleId
    aiAssistToken: String
}

export const state: State = {
    accessRegistration: {
        accessKey: "",
        provider: "",
        emailId: "",
        phoneExt: 91,
        phone: null,
        firstName: "",
        lastName: "",
        userJobTitle: "",
        workspaceName: "",
        workspaceUrl: "",
        isTermOfUseAccepted: false,
        isPromotionalEmailAccepted: false,
    },
    authUser: {
        sessionAccessKey: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: null,
        phoneExt: 91,
        id: "",
        userId: "",
        role: "",
        defaultLanguage: "en",
        isEmailVerified: false,
        isMobileVerified: false,
        showQuestions: false,
        emailHash: "",
    },
    accessLogin: {
        emailId: "",
        accessKey: "",
    },
    defaultLoginDetails: {
        accessToken: "",
        name: "",
        workspaceUrl: "",
    },
    isUserRegistered: false,
    userRoleId: "",
    redirectAfterLogin: {
        name: "",
        params: {},
    },
    teamMembers: {
        email: "",
        firstName: "",
        id: "",
        lastName: "",
        role: "",
        userId: "",
        userRoleId: "",
        userStatus: "",
    },
    aiAssistToken: "",
}
